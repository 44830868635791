/* TODO: manage global styling with NextUI */

/* Fix Nextui button text showing through nav bug https://github.com/nextui-org/nextui/issues/724 */
.nextui-button-text {
  z-index: 0 !important;
}

input {
  font-weight: 500;
}

html,
body {
  background-color: transparent;
}
